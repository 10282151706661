import { faBookmark, faTags } from "@fortawesome/pro-light-svg-icons"
import { faTimes, faTimesSquare } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback } from "react"
import { useFilters } from "../../hooks/use-filters"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { darkTheme, lightTheme } from "../../services/theme"
import HtmlTooltip from "../widgets/html-tooltip"
import { useSideMode, SideMode } from "../../hooks/use-side-mode"
import { showToast } from "../status-notification"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"

const TagFilters: FunctionComponent = () => {
  const { filters, updateFilters } = useFilters()
  const { user, addBookmark } = useUser()
  const { sideMode } = useSideMode()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  const resetFilters = useCallback(async () => {
    updateFilters({ tags: [] })
  }, [updateFilters])

  const removeTag = useCallback(
    async (tag: string) => {
      if (filters && filters.tags) {
        let idx = filters.tags.indexOf(tag)
        if (idx != -1) {
          let tags = filters.tags.slice()
          tags?.splice(idx, 1)
          updateFilters({ tags: tags })
        }
      }
    },
    [filters.tags]
  )

  const addNewBookmark = useCallback(async () => {
    if (filters.tags && filters.tags.length) {
      addBookmark({
        id: Date.now(),
        tags: JSON.parse(JSON.stringify(filters.tags)),
        numViews: 1,
        lastViewed: Math.floor(Date.now() / 1000),
      })
      showToast(faInfoCircle, "Local app", "Bookmark added")
    }
  }, [filters.tags])

  //console.log("**** " + filters)

  return (
    <>
      {filters &&
        filters.tags &&
        filters.tags.length > 0 &&
        sideMode == SideMode.Content && (
          <div className="accordion">
            <section>
              <header>
                <h1>
                  <FontAwesomeIcon icon={faTags} size="lg" />
                  <span>filter by tags</span>
                </h1>
                <div className="filterConrols">
                  <HtmlTooltip title="Bookmark" placement="top">
                    <div className="filterAction">
                      <FontAwesomeIcon
                        icon={faBookmark}
                        style={{ marginRight: "16px" }}
                        onClick={addNewBookmark}
                      />
                    </div>
                  </HtmlTooltip>
                  <div className="highlighted">
                    <FontAwesomeIcon
                      icon={faTimesSquare}
                      onClick={resetFilters}
                      style={{ backgroundColor: "rgb(251, 230, 162)" }}
                    />
                  </div>
                </div>
              </header>
            </section>
            <section>
              <div className="content">
                {filters &&
                  filters.tags &&
                  filters.tags.length > 0 &&
                  filters.tags.map(t => (
                    <div key={t} className="tag" onClick={() => removeTag(t)}>
                      {t}
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{
                          paddingLeft: "4px",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />
                    </div>
                  ))}
              </div>
            </section>
            <style jsx>{`
              .highlighted {
                font-size: 20px;
                text-align: center;
                /*
              color: #39454a;
              border-radius: 50%;
              width: 22px;
              height: 22px;
              background-color: rgb(255, 213, 79);
              line-height: 24px;
              margin: auto;
              */
              }
              .highlighted:hover {
                cursor: pointer;
              }

              header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1em;
                font-family: Roboto;
              }
              header > :global(:not(:first-child)) {
                margin-left: 1em;
              }
              h1 {
                margin: 0;
                padding: 0;
                font-size: inherit;
                font-style: inherit;
                font-weight: normal;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-weight: lighter;
              }
              h1 > *:not(:first-child) {
                margin-left: 1em;
              }

              .accordion {
                background-color: ${css.backgroundColor};
                border: 1px solid ${css.borderColor};
                transition: 200ms background-color, 200ms border-color;
                border-radius: 0.3em;
                font-size: 0.9em;
                user-select: none;
              }
              section:not(:first-child) {
                border-top: 1px solid ${css.borderColor};
                transition: 200ms border-color;
              }
              .content {
                padding: 5px;
                border-top: 1px solid ${css.borderColor};
                flex-wrap: wrap;
                display: inline-flex;
              }
              .tag {
                background-color: rgb(105, 105, 105, 0.1);
                padding: 5px;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 5px;
                font-size: 14px;
                word-break: keep-all;
                user-select: none;
              }
              .tag:hover {
                background-color: rgb(105, 105, 105, 0.3);
                cursor: pointer;
              }
              .filterConrols {
                flex-wrap: wrap;
                display: inline-flex;
                opacity: 0.7;
              }
              .filterAction {
                font-size: 20px;
              }
              .filterAction:hover {
                cursor: pointer;
              }
            `}</style>
          </div>
        )}
    </>
  )
}

export default TagFilters
