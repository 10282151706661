import { useCallback } from "react"
import { Item } from "../types"
import { useItems } from "./use-items"

export const useItem = (initialItem: Item) => {
    const { items, setItems, likeItem: _likeItem } = useItems([initialItem])
    const item = items[0]

    const setItem = useCallback((newItem: Item) => {
        setItems([newItem])
    }, [])

    const likeItem = useCallback((amount: number) => _likeItem(item, amount), [
        item
    ])

    return {
        item,
        setItem,
        likeItem
    }
}
