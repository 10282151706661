import React, { FunctionComponent, useCallback, useEffect } from "react"
import { Helmet as Head } from "react-helmet"
import Comments from "../components/comment/comments"
import ItemPreview from "../components/item/preview"
import Bookmarks from "../components/layout/bookmarks"
import SortFilter from "../components/layout/sort-filter"
import TagFilters from "../components/layout/tag-filters"
import ThreeColumnLayout from "../components/layout/three-column-layout"
import { useFilters } from "../hooks/use-filters"
import { useItem } from "../hooks/use-item"
import { useItems } from "../hooks/use-items"
import { useMedia } from "../hooks/use-media"
import { convertContentItems } from "../services/content"
import { GET_item } from "../services/http"
import { LikeType, MuteType } from "../types"

const CommentsPage: FunctionComponent = ({ location }) => {
  console.log("comments")
  const parts = location.pathname.split("/")
  const itemId = parts[2]

  const { item, setItem } = useItem(undefined)
  const { updateItem, likeItem } = useItems([])
  const { filters } = useFilters()
  const { numColumns } = useMedia()

  useEffect(() => {
    if (!item || item.id != itemId) {
      console.log("loading item " + itemId)
      GET_item(itemId).then(response => {
        if (response?.hits?.hits.length) {
          let items = convertContentItems(response, filters)
          if (items.length) {
            setItem(items[0])
            console.log("setting item url " + items[0].url)
          }
        }
      })
    }
  }, [filters, item])

  const onMute = useCallback((id: string, kind: MuteType) => {}, [])

  return (
    <>
      {item && (
        <Head>
          <title>{item.title} | Smartlike</title>
          <meta property="og:title" content={`${item.title} | Smartlike`} />
          <meta name="description" content={item.description} />
          <meta property="og:description" content={item.description} />
          {item.image && <meta property="og:image" content={item.image} />}
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/item/${item.id}`}
          />
        </Head>
      )}

      <ThreeColumnLayout>
        {{
          content: (
            <>
              {item && (
                <>
                  {numColumns != 1 && (
                    <ItemPreview
                      liked={filters.liked}
                      item={item}
                      onLike={likeItem}
                      onUpdate={updateItem}
                      onMute={onMute}
                      onShowComments={undefined}
                    />
                  )}

                  <Comments
                    target={{
                      kind: LikeType.Donate,
                      id: item?.id,
                      url: item?.url,
                      count: 0,
                    }}
                    parentComment={""}
                  />
                </>
              )}
            </>
          ),
          sidebar: (
            <>
              <SortFilter />
              <TagFilters />
              <Bookmarks />
            </>
          ),
        }}
      </ThreeColumnLayout>
    </>
  )
}
export default CommentsPage
