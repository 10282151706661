import {
  faBookmark,
  faChevronSquareDown,
  faChevronSquareUp,
  faTimes,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback } from "react"
import { useFilters } from "../../hooks/use-filters"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { darkTheme, lightTheme } from "../../services/theme"
import { useSideMode, SideMode } from "../../hooks/use-side-mode"

const Bookmarks: FunctionComponent = () => {
  const { filters, updateFilters } = useFilters()
  const { sideMode } = useSideMode()

  const {
    user,
    selectBookmark,
    removeBookmark,
    showBookmarks,
    setShowBookmarks,
  } = useUser()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  const resetFilters = useCallback(async () => {
    filters.tags = []
    updateFilters(filters)
  }, [])

  const _selectBookmark = useCallback(
    async (id: number) => {
      //console.log("************************* _selectBookmark")

      if (user) {
        for (var i = 0; i < user.bookmarks.length; i++) {
          if (user.bookmarks[i].id == id) {
            //console.log("select bookmark " + id + " " + JSON.stringify(user.bookmarks[i]))
            updateFilters({
              tags: user.bookmarks[i].tags.slice(),
            })
            break
          }
        }
      }
      selectBookmark(id)
      //e.preventDefault()
    },
    [user, user?.bookmarks, filters]
  )

  const _removeBookmark = useCallback(
    async (e, id: number) => {
      removeBookmark(id)
      e.preventDefault()
    },
    [removeBookmark, filters]
  )

  const toggleShowBookmarks = useCallback(async () => {
    setShowBookmarks(!showBookmarks)
  }, [showBookmarks])

  //console.log("**** " + filters)

  return (
    <>
      {user &&
        user.bookmarks &&
        user.bookmarks.length > 0 &&
        sideMode == SideMode.Content && (
          <div className="accordion">
            <section>
              <header onClick={toggleShowBookmarks} className="header">
                <h1>
                  <FontAwesomeIcon icon={faBookmark} size="lg" />
                  <span>Bookmarks</span>
                </h1>
                {showBookmarks ? (
                  <div className="filterConrols">
                    <div className="filterAction">
                      <FontAwesomeIcon icon={faChevronSquareUp} />
                    </div>
                  </div>
                ) : (
                  <div className="filterConrols">
                    <div className="filterAction">
                      <FontAwesomeIcon icon={faChevronSquareDown} />
                    </div>
                  </div>
                )}
              </header>
            </section>
            {showBookmarks &&
              user &&
              user.bookmarks &&
              user.bookmarks.length > 0 && (
                <section>
                  <div className="content">
                    {user.bookmarks.map(b => (
                      <div
                        key={b.id}
                        className="bookmark"
                        onClick={() => _selectBookmark(b.id)}
                      >
                        <div className="tags">
                          <div className="tags-inner">
                            {b.tags.map(t => (
                              <div key={t} className="tag">
                                {t}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="delete-bookmark">
                          <div style={{ padding: "9px" }}>
                            <FontAwesomeIcon
                              onClick={e => _removeBookmark(e, b.id)}
                              icon={faTimes}
                              style={{
                                fontSize: "12px",
                                float: "right",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              )}
            <style jsx>{`
        .header {
        }
        .header:hover {
          cursor: pointer;
        }
        .delete-bookmark {
          /*height: 20px;
          vertical-align: middle;
          font-size: 12px;
          float: right;*/
          width: 20px;
          display: table-cell;
          z-index: 10000;
          opacity: 0.5;
        }
        .delete-bookmark:hover {
          color: blue;
          opacity: 1;
          z-index: 10000;
        }

        .bookmark {
          display: table;
          width: 100%;
          /*padding-top: 5px;*/
          padding-top: 1px;
          padding-bottom: 1px;
        }
        .bookmark:hover {
          /*background-color: rgb(249, 249, 249);*/
          background-color: rgba(105, 105, 105, 0.15);
          cursor: pointer;
          border-radius: 4px;
        }
  
        @media (hover: hover) and (pointer: fine) {
          .delete-bookmark {
            visibility: hidden;
          }
    
          .bookmark:hover .delete-bookmark {
            visibility: visible;
          }
        }



        .tags {
          display: table-cell;
          padding-left: 5px;
        }
        .tags-inner {
          display: flex;
          flex-flow: wrap;
        }
        header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1em;
          font-family: Roboto;
        }
        header > :global(:not(:first-child)) {
          margin-left: 1em;
        }
        h1 {
          margin: 0;
          padding: 0;
          font-size: inherit;
          font-style: inherit;
          font-weight: normal;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-weight: lighter;
        }
        h1 > *:not(:first-child) {
          margin-left: 1em;
        }

        .accordion {
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
          border-radius: 0.3em;
          font-size: 0.9em;
          user-select: none;
          margin-bottom: 40px;
        }
        /*
        section:not(:first-child) {
          border-top: 1px solid ${css.borderColor};
          transition: 200ms border-color;
        }*/
        .content {
          /*padding: 5px;*/
          border-top: 1px solid ${css.borderColor};
          /*flex-wrap: wrap;
          display: inline-flex;*/
        }
        .tag {
          background-color: rgb(105, 105, 105, 0.1);
          padding: 5px;
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 5px;
          font-size: 14px;
          word-break: keep-all;
          user-select: none;
        }
        .filterConrols {
          flex-wrap: wrap;
          display: inline-flex;
          opacity: 0.7;
        }
        .filterAction {
          font-size: 20px;
        }
        .filterAction:hover {
          cursor: pointer;
        }
      `}</style>
          </div>
        )}
    </>
  )
}

export default Bookmarks
